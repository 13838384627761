
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'List'
})
export default class List extends Vue {
  private info = {
    projectName: ''
  }

  private loading = false
  private tableData = []
  private page = 1
  private size = 10
  private total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.visitor.selectAppUserFeedbackByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'visitorFeedbackDetail', params: { id } })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
